import React from "react";

const mulberry32 = (a) => () => {
  var t = (a += 0x6d2b79f5);
  t = Math.imul(t ^ (t >>> 15), t | 1);
  t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
  return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
};

const inRange = (random, a, b) => Math.round(a + random() * (b - a));

const Shape = ({ type, color, random, fillType, sizeRange }) => {
  switch (type) {
    case "square":
      return (
        <Square
          color={color}
          random={random}
          fillType={fillType}
          sizeRange={sizeRange}
        />
      );
    case "rect":
      return (
        <Rect
          color={color}
          random={random}
          fillType={fillType}
          sizeRange={sizeRange}
        />
      );
    case "circle":
      return (
        <Circle
          color={color}
          random={random}
          fillType={fillType}
          sizeRange={sizeRange}
        />
      );
    case "roundedSquare":
      return (
        <RoundedSquare
          color={color}
          random={random}
          fillType={fillType}
          sizeRange={sizeRange}
        />
      );
    default:
      return null;
  }
};

const Rect = ({ color, random, fillType = "both", sizeRange = [100, 400] }) => {
  const fill = inRange(
    random,
    ...(fillType === "stroke" ? [0, 0] : fillType === "fill" ? [1, 1] : [0, 1])
  );

  return (
    <rect
      width={inRange(random, ...sizeRange)}
      height={inRange(random, ...sizeRange)}
      strokeWidth={fill ? "0px" : "8px"}
      stroke={color}
      fill={fill ? color : "transparent"}
      style={{
        transform: `rotate(${inRange(random, 0, 360)}deg)`,
      }}
    />
  );
};

const Square = ({
  color,
  random,
  fillType = "both",
  sizeRange = [100, 400],
}) => {
  const w = inRange(random, ...sizeRange);

  const fill = inRange(
    random,
    ...(fillType === "stroke" ? [0, 0] : fillType === "fill" ? [1, 1] : [0, 1])
  );

  return (
    <rect
      width={w}
      height={w}
      strokeWidth={fill ? "0px" : "8px"}
      stroke={color}
      fill={fill ? color : "transparent"}
      style={{
        transform: `rotate(0, ${inRange(random, 0, 360)}deg, 0)`,
      }}
    />
  );
};

const Circle = ({
  color,
  random,
  fillType = "both",
  sizeRange = [100, 400],
}) => {
  const w = inRange(random, ...sizeRange);

  const fill = inRange(
    random,
    ...(fillType === "stroke" ? [0, 0] : fillType === "fill" ? [1, 1] : [0, 1])
  );

  return (
    <rect
      width={w}
      height={w}
      rx={w}
      ry={w}
      strokeWidth={fill ? "0px" : "8px"}
      stroke={color}
      fill={fill ? color : "transparent"}
      style={{
        transform: `rotate(0, ${inRange(random, 0, 360)}deg, 0)`,
      }}
    />
  );
};

const RoundedSquare = ({
  color,
  random,
  fillType = "both",
  sizeRange = [100, 400],
}) => {
  const w = inRange(random, ...sizeRange);
  const r = inRange(random, 4, 100);

  const fill = inRange(
    random,
    ...(fillType === "stroke" ? [0, 0] : fillType === "fill" ? [1, 1] : [0, 1])
  );

  return (
    <rect
      width={w}
      height={w}
      rx={r}
      ry={r}
      strokeWidth={fill ? "0px" : "8px"}
      stroke={color}
      fill={fill ? color : "transparent"}
      style={{
        transform: `rotate(${inRange(random, 0, 360)}deg)`,
      }}
    />
  );
};

const Pattern = ({
  positionRange = [0, 100],
  sizeRange = [100, 400],
  background = "#223D7D",
  randomState = Math.random() * 1000000,
  colors = ["#F8B508", "#EF86B0", "#C25C5C", "#7DDAA4"],
  shapes = ["square", "rect", "circle", "roundedSquare"],
  fillType = "both",
  overflow = "hidden",
}) => {
  const random = mulberry32(randomState);

  return React.useMemo(
    () => (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: background,
          overflow,
          zIndex: -1,
        }}
      >
        <svg
          style={{
            width: "100%",
            height: "100%",
            background: "none",
            overflow: "visible",
            flexGrow: 1,
          }}
        >
          {colors.map((color) => (
            <g
              key={color}
              style={{
                transform: `translate(${inRange(
                  random,
                  ...positionRange
                )}%, ${inRange(random, ...positionRange)}%`,
              }}
            >
              <Shape
                random={random}
                type={shapes[inRange(random, 0, shapes.length - 1)]}
                color={color}
                fillType={fillType}
                sizeRange={sizeRange}
              />
            </g>
          ))}
        </svg>
      </div>
    ),
    []
  );
};

export default Pattern;
