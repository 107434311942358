import React from "react";
import classes from "../../utils/classes";

import "./index.scss";

const AppsContainer = ({ className, ...props }) => {
  return (
    <div className={classes("app-container", className)}>
      <a
        className="button app-container--emergence"
        href="https://emergence.golsteyn.com"
        draggable={false}
      >
        <img
          src="/image/apps/emergence.svg"
          width={64}
          height={64}
          alt="Emergence"
          draggable={false}
        />
      </a>
      <div className="app-container--disabled"></div>
      <div className="app-container--disabled"></div>
      <div className="app-container--disabled"></div>
    </div>
  );
};

export default AppsContainer;
