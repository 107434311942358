import * as React from "react";
import { graphql, Link } from "gatsby";
import { motion } from "framer-motion";

import * as indexStyles from "./__styles/index.module.scss";

import Pattern from "../components/pattern";
import { NavContext } from "../components/nav";
import classes from "../utils/classes";
import Meta from "../components/meta";
import AppsContainer from "../components/apps";

const IndexPage = ({ data }) => {
  const navContext = React.useContext(NavContext);
  React.useEffect(() => {
    navContext.setNav([]);
  }, []);

  return (
    <motion.div
      className="sidebar-left"
      initial={{ opacity: 0 }}
      variants={{
        enter: { opacity: 1, transition: { duration: 0.4 } },
        exit: { opacity: 0, transition: { duration: 0.2 } },
      }}
    >
      <Meta
        title={`Quentin Golsteyn`}
        description="The personal website of Quentin Golsteyn, a front-end developer based in Vancouver, Canada."
        canonical={`https://golsteyn.com/`}
      />
      <aside>
        <div className={indexStyles.aside__illustration}>
          <Pattern positionRange={[20, 80]} sizeRange={[100, 500]} />
        </div>
        <div className={indexStyles.aside__title}>
          <h1 className="h4 title">Quentin Golsteyn</h1>
          <span className="subtitle">
            Front-end developer based in Vancouver, Canada.
          </span>
        </div>
        <div className={indexStyles.aside__apps}>
          <h2 className="h6 section-header">My apps</h2>
          <AppsContainer />
        </div>
      </aside>
      <article>
        {data.allCategoryJson.nodes.map((node, index) => (
          <section className={indexStyles.postSection} key={node.id}>
            <h2 className="h4 section-header">{node.name}</h2>
            {node.posts
              .sort(
                (a, b) =>
                  new Date(b.frontmatter.date).getTime() -
                  new Date(a.frontmatter.date).getTime()
              )
              .map((post) => (
                <Link
                  key={post.id}
                  className={indexStyles.post}
                  to={"/writing/" + post.node.name}
                >
                  <h3 className="h6 title underline">
                    {post.frontmatter.title}
                  </h3>
                  <span className="subtitle">{post.frontmatter.subtitle}</span>
                </Link>
              ))}
          </section>
        ))}
      </article>
    </motion.div>
  );
};

export const query = graphql`
  {
    allCategoryJson {
      nodes {
        id
        name
        posts {
          id
          node {
            name
          }
          frontmatter {
            title
            subtitle
            date
          }
        }
      }
    }
  }
`;

export default IndexPage;
